<template>
  <div class="vaxellosningar">
    <PageTitle title="Växellösningar"/>

    <CardsGrid>
      <Card
        v-if="false"
        title="AllTele"
        img="alltele"
        link="https://foretag.alltele.se">
        <div>AllTele levererar växellösningar.</div>
      </Card>
      <Card
        title="Bredband2"
        img="bredband2"
        link="https://bredband2.com">
        <div>Sveriges tredje största leverantör via fiber.</div>
        <div>Som Bredband2-kund får du en framtidssäker uppkoppling som är driftsäker och pålitlig.</div>
      </Card>
      <Card
        title="Net at Once"
        img="netatonce"
        link="https://netatonce.se"
        phone="0771 – 40 44 00">
        <div>Levererar bredband, telefoni samt fiber och växellösningar.</div>
      </Card>
      <Card
        title="QuickNet"
        img="quicknet"
        link="https://quicknet.se"
        link-private="https://quicknethemma.se"
        link-company="https://quicknet.se"
        phone="021 - 338 00 00">
        <div>Levererar bredband, telefoni och växellösningar.</div>
      </Card>
      <Card
        title="Tele2"
        img="tele2"
        link="https://tele2.se/foretag"
        phone="0200 - 23 23 23">
        <div>Levererar växellösningar, telefoni och bredband.</div>
      </Card>
      <Card
        title="Telia"
        img="telia"
        link="https://telia.se/foretag/aktuellt/framtidensnat-foretag"
        link-company="https://telia.se/foretag/aktuellt/framtidensnat-foretag"
        title-link-company="Framtidens nät Företag"
        phone="020 - 30 50 50">
        <div>Levererar telefoni, TV, bredband samt fiber och växellösningar.</div>
      </Card>
    </CardsGrid>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle'
import CardsGrid from '@/components/CardsGrid'
import Card from '@/components/Card'

export default {
  components: {
    CardsGrid,
    PageTitle,
    Card
  }
}
</script>

<style lang="scss">

</style>
